import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthService from "../services/auth.service";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Resuelve aqui
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const form = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [authMesg, setAuthMesg] = React.useState("");
  const checkBtn = React.useRef();
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleUserName = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setUserName(value);
  };
  const handlePassword = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setPassword(value);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (userName === "") {
      setAuthMesg("El campo de usuario no puede estar vacio");
      handleClick();
      return;
    }
    if (password === "") {
      setAuthMesg("El campo de contraseña no puede estar vacio");
      handleClick();
      return;
    }
    AuthService.login(userName, password).then(
      () => {
        //navigate("/profile");
        window.location.href = "/";
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        if (resMessage === "Bad credentials") {
          setAuthMesg("Nombre de usuario o contraseña incorrectos");
          handleClick();
        }else{
          setAuthMesg(resMessage);
          handleClick();
        }
      }
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {authMesg}
        </Alert>
      </Snackbar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#E32740" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
          Acceder
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              value={userName}
              onChange={handleUserName}
              label="Nombre de usuario"
              name="username"
              autoComplete="username"
              autoFocus
              color="error"
              
            />
            <TextField
              margin="normal"
              required
              fullWidth
              value={password}
              onChange={handlePassword}
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              color="error"
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            <Button
              onClick={handleLogin}
              fullWidth
              variant="contained"
              color="error"

              sx={{ mt: 3, mb: 2 }}
            >
              Acceder
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/resetpassword" variant="body2">
                  Restaurar contraseña
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup" variant="body2">
                  {"No tienes una cuenta? Registrate"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
