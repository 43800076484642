import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import authHeader from "../services/auth-header";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { validateEmail } from "../utils/helpers";
import { baseUrl } from "../utils/constants";
import axios from "axios";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import OrderList from "../components/OrderList";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Resuelve aqui
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Orders() {
  const [orders, setOrders] = React.useState([]);
  const currentUser = AuthService.getCurrentUser();

  React.useEffect(() => {
    axios
      .get(baseUrl.concat("api/orders/getOrderUser/").concat(currentUser['id']),{ headers: authHeader() })
      .then((response) => {
        if (response.data.length === 0) {
        } else {
          setOrders(response.data);
        }
      })
      .catch(console.error);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="lg">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            {orders.length === 0 ? " Aun no tiene pedidos" : "Mis Pedidos"}
          </Typography>
          <br />
          <br />
        </Box>
        <OrderList orders={orders} />
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
