import axios from "axios";
import authHeader from "./auth-header";
import { baseUrl as url } from '../utils/constants'
const getPublicContent = () => {
  return axios.get(url + "api/test/all");
};
const getUserBoard = () => {
  return axios.get(url + "api/test/user", { headers: authHeader() });
};
const getModeratorBoard = () => {
  return axios.get(url + "api/test/mod", { headers: authHeader() });
};
const getAdminBoard = () => {
  return axios.get(url + "api/test/admin", { headers: authHeader() });
};
const updateUser = (userJson) => {
  return axios
    .put(url + "api/users/".concat(userJson.id), userJson, { headers: authHeader() })
    .then((response) => {
      if (response) {
        //console.log("all good");
      }
      return response.data;
    });
};
const getUser = (id) => {
  return axios
    .get(url + "api/users/".concat(id), { headers: authHeader() })
    .then((response) => {
      if (response) {
        //console.log("all good");
      }
      return response.data;
    });
};
const UserService = {
  getPublicContent,
  getUserBoard,
  getModeratorBoard,
  getAdminBoard,
  updateUser,
  getUser
};
export default UserService;