import axios from "axios";
import authHeader from "./auth-header";
import { baseUrl as url } from "../utils/constants";

const makePayment = (paymentJson) => {
  return axios
    .post(url + "api/sales/create_sale", paymentJson, { headers: authHeader() })
    .then((response) => {
      if (response) {
        //console.log("all good");
      }
      return response.data;
    });
};

const PaymentService = {
  makePayment,
};
export default PaymentService;
