import axios from 'axios'
import React, { useContext, useEffect, useReducer } from 'react'
import reducer from '../reducers/products_reducer'
import { products_url as url } from '../utils/constants'
import { search_url as url_s } from '../utils/constants'


import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  FILTER_SIDEBAR_OPEN,
  FILTER_SIDEBAR_CLOSE,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
  GET_OPTION_VALUE
} from '../actions'

const initialState = {
  totalPages : 0,
  isSidebarOpen: false,
  isFilterSidebarOpen: false,
  optionTextValue:'deporte',
  products_loading: false,
  products_error: false,
  products: [],
  categories: [],
  featured_products: [],
  single_product_loading: false,
  single_product_error: false,
  single_product: {},
}

const ProductsContext = React.createContext()

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const openSidebar = () => {
    dispatch({ type: SIDEBAR_OPEN })
  }
  
  const closeSidebar = () => {
    dispatch({ type: SIDEBAR_CLOSE })
  }
  const openFilterSidebar = () => {
    dispatch({ type: FILTER_SIDEBAR_OPEN })
  }
  const setOptionValue = (e) => {
    dispatch({ type: GET_OPTION_VALUE ,data:e})
  }
  const closeFilterSidebar = () => {
    dispatch({ type: FILTER_SIDEBAR_CLOSE })
  }


  const getProductsByPage = (e) => {
    const page = e.target.textContent
    fetchProducts(url,parseInt(page) - 1);
  }
  const filterProductsBySearch = (e) => {
    e.preventDefault();
    
    const productType = e.target[0].value 
    const searchValue = e.target[2].value
    if(productType == "todos"){
      searchProducts(url_s,"",searchValue);
    }else{
      searchProducts(url_s,productType,searchValue);
    }
    
   
   // window.location.href = window.location.href+"products"
  }

  const searchProducts = async (url,productType,searchValue) => {
   
    dispatch({ type: GET_PRODUCTS_BEGIN })
    try {
      const response = await axios.get(url+"?name="+searchValue+"&type="+productType)
      const products = response.data
      console.log("searching"+url+"?name="+searchValue+"&type="+productType);
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: products})
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR })
    }

  }


  const fetchProducts = async (url,page) => {
    dispatch({ type: GET_PRODUCTS_BEGIN })
    try {
      const response = await axios.get(url+"?page="+page)
      const products = response.data
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: products})
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR })
    }
  }
  const fetchSingleProduct = async (url) => {
    dispatch({ type: GET_SINGLE_PRODUCT_BEGIN })
    try {
      const response = await axios.get(url)
      const singleProduct = response.data
      dispatch({ type: GET_SINGLE_PRODUCT_SUCCESS, payload: singleProduct })
    } catch (error) {
      dispatch({ type: GET_SINGLE_PRODUCT_ERROR })
    }
  }

  useEffect(() => {
    fetchProducts(url,0);
  }, [])

  return (
    <ProductsContext.Provider
      value={{
        ...state,
        openSidebar,
        closeSidebar,
        openFilterSidebar,
        setOptionValue,
        closeFilterSidebar,
        fetchSingleProduct,
        getProductsByPage,
        filterProductsBySearch,
      }}
    >
      {children}
    </ProductsContext.Provider>
  )
}
// make sure use
export const useProductsContext = () => {
  return useContext(ProductsContext)
}
