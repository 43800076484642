import React, { useEffect } from "react";
import authHeader from "../services/auth-header";
import { useParams, useHistory } from "react-router-dom";
import { useProductsContext } from "../context/products_context";
import { baseUrl, single_product_url as url } from "../utils/constants";
import { formatPrice } from "../utils/helpers";
import { Error, AddToCart, PageHero } from "../components";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Container from "@mui/material/Container";
const OrderDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const [order, setOrder] = React.useState([]);
  const [invoiceUrl, setInvoiceUrl] = React.useState([]);
  const [amount, setAmount] = React.useState(0);
  useEffect(() => {
    axios
      .get(baseUrl.concat("api/orders/getOrder/").concat(id),{ headers: authHeader() })
      .then((response) => {
        console.log(response.data);
        if (response.data.length === 0) {
        } else {
          setOrder(response.data);
          setAmount(response.data.sale.amount);
          setInvoiceUrl(response.data.bill_payment);
        }
      })
      .catch(console.error);
  }, []);


   const onButtonClick = () => {

    axios({
      url:baseUrl.concat("invoice/").concat(invoiceUrl),
      method: "GET",
      responseType: "blob", // importante
      headers: authHeader(),
      onDownloadProgress: (progressEvent) => {
         var percentCompleted = Math.round((progressEvent.loaded * 100) /  progressEvent.total);
         console.log(percentCompleted)
      },
    }).then((response) => {
       const url = window.URL.createObjectURL(new  Blob([response.data]));
       const link = document.createElement("a");
       link.href = url;
       link.setAttribute("download", "p.pdf");
       document.body.appendChild(link);
       link.click();
    });

  
}

  return (
    <Wrapper>
      <Container component="main" maxWidth="lg">
        <br/>
        <Typography component="h1" variant="h5">
          Detalles del Pedido
        </Typography>
        <div className="section section-center page">
          <Link to="/orders" className="btn">
            Volver a Ordenes
          </Link>
          <div className="product-center">
            <section className="content">
              <p className="info">
                <span>Nombre :</span>
                {order.name}
              </p>
              <p className="info">
                <span>Fecha :</span>
                {order.dateAndTime}
              </p>
              <p className="info">
                <span>Estado : </span>
                {order.status === 1
                  ? "Comprado"
                  : order.status === 2
                  ? "En revision"
                  : order.status === 3
                  ? "En Camino"
                  : "Entregado"}
              </p>
              <p className="info">
                <span>Importe :</span>
                {amount}
              </p>
              <p className="info">
                <span>Dirección : </span>
                {order.address}
              </p>
              <button onClick={onButtonClick} className="btn">
              Descargar Factura PDF
                </button>
               {/* <a target="_blank"  href={baseUrl.concat("invoice/").concat(invoiceUrl)} className="btn">
                Descargar Factura
              </a>  */}
            </section>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  .product-center {
    display: grid;
    gap: 4rem;
    margin-top: 2rem;
  }
  .price {
    color: var(--clr-primary-5);
  }
  .desc {
    line-height: 2;
    max-width: 45em;
  }
  .info {
    text-transform: capitalize;
    width: 300px;
    display: grid;
    grid-template-columns: 125px 1fr;
    span {
      font-weight: 700;
    }
  }

  @media (min-width: 992px) {
    .product-center {
      grid-template-columns: 1fr 1fr;
      align-items: center;
    }
    .price {
      font-size: 1.25rem;
    }
  }
`;

export default OrderDetail;
