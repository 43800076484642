import React from 'react'
import styled from 'styled-components'
import { formatPrice } from '../utils/helpers'
import { FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { baseUrl as url } from '../utils/constants'
const Product = ({ img, name, price, id }) => {
  const thumbImage = img.split(',')[0];
  return (
    <Wrapper>
      
      <div className='container'>
      <Link to={`/products/${id}`} >
        <img src={url+"product-images/Thumbs/"+thumbImage} alt={name} />
        <div className='= link'>
          <FaSearch fontSize="small"/>
          </div>
        
        </Link>
      </div>
      <footer>
        <h5>{name}</h5>
        <p>{formatPrice(price)}</p>
      </footer>
      
    </Wrapper>
  )
}
const Wrapper = styled.article`

.link {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--clr-ecomerce-1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: var(--transition);
  opacity: 0;
  cursor: pointer;
  svg {
    font-size: 1.25rem;
    color: var(--clr-white);
  }
}

  .container {
    position: relative;
    background: var(--clr-black);
    border-radius: var(--radius);
    
  }
  img {
    width: 100%;
    display: block;
    object-fit: cover;
    border-radius: var(--radius);
    transition: var(--transition);
  }
 
  .container:hover img {
    opacity: 0.5;
  }
  .container:hover .link {
    opacity: 1;
  }
  footer {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  footer h5,
  footer p {
    margin-bottom: 0;
    font-weight: 400;
  }

  footer p {
    color: var(  --clr-ecomerce-price-5);
    letter-spacing: var(--spacing);
  }
  footer h5 {
    color: var(   --clr-ecomerce-text-5);
  }
`
export default Product
