import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import AuthService from "../services/auth.service";
import parseJwt from '../utils/helpers';

const PrivateRoute = ({ children, ...rest }) => {
  const currentUser = AuthService.getCurrentUser();
  const { user } = useAuth0()
  return (
    <Route
      {...rest}
      render={() => {

        if(localStorage.getItem("accessToken") != undefined){
          const tokenDate = parseJwt(localStorage.getItem("accessToken"));
          const date = new Date();
          const currentDate = date.getTime()/1000;
          console.log(tokenDate.exp);
          console.log(currentDate - tokenDate.exp);
          if(currentDate - tokenDate.exp  > 0){
            localStorage.setItem("accessToken","");
            localStorage.setItem("user","");
            return  <Redirect to='/signin'></Redirect>
          }
        }


        return currentUser ? children : <Redirect to='/'></Redirect>
      }}
    ></Route>
  )
}
export default PrivateRoute
