import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddressForm from "./AddressForm";
import PaymentForm from "./PaymentForm";
import Review from "./Review";
import { useCartContext } from "../../context/cart_context";
import { useUserContext } from "../../context/user_context";
import AuthService from "../../services/auth.service";
import PaymentService from "../../services/payment.service";
import { Redirect, useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { Alert, AlertTitle, Dialog, DialogTitle, DialogContent, Fade } from "@mui/material";
import { red } from '@mui/material/colors';

const steps = ["Dirección de Envío", "Detalles del pago", "Revise su orden"];


const theme = createTheme();

export default function Checkout(props) {
  const [model, setModel] = React.useState(props.initModel);
  const { cart, total_amount, shipping_fee, clearCart } = useCartContext();
  const [activeStep, setActiveStep] = React.useState(0);
  const [stripeToken, setStripeToken] = React.useState("");
  const [errorMesage, setErrorMessage] = React.useState("");
  const [shippingFirstName, setShippingFirstName] = React.useState("");
  const [shippingLastName, setShippingLastName] = React.useState("");
  const [shippingAddress, setShippingAddress] = React.useState("");
  const [shippingPhone, setShippingPhone] = React.useState("");
  const [shippingState, setShippingState] = React.useState("");
  const [shippingZip, setShippingZip] = React.useState("");
  const [shippingCountry, setShippingCountry] = React.useState("");
  const { myUser } = useUserContext();
  const currentUser = AuthService.getCurrentUser();
  const history = useHistory();

  React.useEffect(() => {
    setModel(props.initModel);
  }, [props.initModel]);

  // Set Values from the input to the model when the values changed
  const handleAddressFirstName = (event) => {
    console.log(event?.target?.value);
    // get the value of the field
    const value = event?.target?.value;
    setShippingFirstName(value);
    //set it in the model
    setModel({
      ...model,
      [event.target.name]: value,
    });
  };
  const handleAddressLastName = (event) => {
    const value = event?.target?.value;
    setShippingLastName(value);
  };
  const handleAddress = (event) => {
    const value = event?.target?.value;
    setShippingAddress(value);
  };
  const handleState = (event) => {
    const value = event?.target?.value;
    setShippingState(value);
  };
  const handleZip = (event) => {
    const value = event?.target?.value;
    setShippingZip(value);
  };
  const handleCountry = (event) => {
    const value = event?.target?.value;
    setShippingCountry(value);
  };
  const handlePhone = (event) => {
    const value = event?.target?.value;
    setShippingPhone(value);
  };
  const handleNext = () => {
    console.log(activeStep);
    if (activeStep == 0) {
      if (
        shippingAddress === "" ||
        shippingCountry === "" ||
        shippingFirstName === "" ||
        shippingLastName === "" ||
        shippingZip === "" ||
        shippingState === "" ||
        shippingPhone === ""
      ) {
        setErrorMessage("Rellene todos los campos");
        return;
      }else{
        setErrorMessage(null);
      }
    } else if (activeStep >= 2) {
      handleSubmit();
    }
    setActiveStep(activeStep + 1);
  };
  const handleCardError =  (error) => {
    
      console.log(error);
    setErrorMessage(error);
    
    
  };

///Dialog
  const [open, setOpen] = React.useState(false);
  const [redirect, setRedirect] =React.useState(false);
  ///End Dialog

  ///SUCESS!!!

  const [query, setQuery] = React.useState('progress');

 
  ///END SUCESSS!!!


  const handleSubmitPayment = async (tokenValue) => {
   
    setOpen(true);

    if (tokenValue.id) {
      setTimeout(function(){
        setStripeToken(tokenValue.id);
        setOpen(false);
        setActiveStep(activeStep + 1);
        // setRedirect(true);
      }, 3000);

      //setStripeToken(tokenValue.id);
      
      //setActiveStep(activeStep + 1);
    } else {
      setOpen(false);
      <Alert variant="filled" severity="error">
        Error desconocido
      </Alert>
      setErrorMessage("Error desconocido");
    }

    console.log("This is the token value: " + tokenValue);
  }

  function getStepContent(step, cart) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            model={model}
            // pass the function to child component
            handleAddressFirstName={handleAddressFirstName}
            handleAddressLastName={handleAddressLastName}
            handleAddress={handleAddress}
            handleCountry={handleCountry}
            handleZip={handleZip}
            handleState={handleState}
            handlePhone={handlePhone}
          />
        );
      case 1:
        return (
          <div>
             <PaymentForm
            handleSubmitPayment={handleSubmitPayment}
            handleCardError={handleCardError}
          />
          <Dialog open={open}>
            <DialogTitle>
            <CircularProgress color="error"/>
            </DialogTitle>
          </Dialog>
          </div>
         
          
        );
      case 2:
         return <Review products={cart} />;
      default:
        throw new Error("Unknown step");
    }
  }
  // if (redirect) {
  //   return <Redirect to="/" />
  // }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = async (ev) => {
    const formData = new FormData();
    setOpen(true);
    const productSold = [
      {
        id: 87,
        quantity: 2,
      },
    ];

    const arrProducts = [];
    var totalAmount = 0;
    for (let i = 0; i < cart.length; i += 1) {
      arrProducts.push({
        id: cart[i].id,
        quantity: cart[i].amount,
      });
      totalAmount = totalAmount + (cart[i].amount*cart[i].price);
    }

    formData.append(
      "productos",
      new Blob([JSON.stringify(arrProducts)], {
        type: "application/json",
      })
    );
    formData.append(
      "amount",
      new Blob([JSON.stringify(total_amount / 100)], {
        type: "application/json",
      })
    );
    formData.append(
      "user_id",
      new Blob([JSON.stringify(currentUser.id)], {
        type: "application/json",
      })
    );

    formData.append(
      "order",
      new Blob(
        [
          JSON.stringify({
            dateAndTime: "0",
            status: 1,
            address: shippingAddress,
            city: shippingState,
            province: shippingCountry,
            zipcode: shippingZip,
            name: shippingFirstName + " " + shippingLastName,
            telef: shippingPhone,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    const paymentData = { id: stripeToken };
    formData.append(
      "request",
      new Blob(
        [
          JSON.stringify({
            description: "resuelve aqui products",
            amount: totalAmount * 100,
            currency: "USD",
            stripeEmail: "test@gmail.com",
            token: paymentData,
          }),
        ],
        {
          type: "application/json",
        }
      )
    );
    console.log(formData);

    PaymentService.makePayment(formData).then(
      (response) => {
        console.log(response);
        setTimeout(() => {
          clearCart();
          setQuery('success');
          history.push("/");
        }, 1000);
        // window.location.href = "/";
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          setOpen(false);
          setErrorMessage(error.response.data.detail);
          //titulo del error
        console.log(error.response.data.title);
        //descripcion del error
        console.log(error.response.data.detail);
        setActiveStep(activeStep - 1);
      }
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "#E32740" }}>
              <LockOutlinedIcon />
            </Avatar>
          </Box>

          <Typography component="h1" variant="h5" align="center">
          Verificar
          </Typography>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step sx={{
                '& .MuiStepLabel-root .Mui-completed': {
                  color: red[600], // circle color (COMPLETED)
                },
                '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                  {
                    color: 'grey.500', // Just text label (COMPLETED)
                  },
                '& .MuiStepLabel-root .Mui-active': {
                  color: red[600], // circle color (ACTIVE)
                },
                '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                  {
                    color: 'common.white', // Just text label (ACTIVE)
                  },
                '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                  fill: 'common.white', // circle's number (ACTIVE)
                },
              }} key={label}>
                <StepLabel >{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
             <Dialog open={open} >
                <DialogTitle>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Box sx={{ alignItems: 'center' }}>
                            {query === 'success' ? (
                              <Typography>¡Éxito!</Typography>
                            ) : (
                              <Fade
                                in={query === 'progress'}
                                style={{
                                  transitionDelay: query === 'progress' ? '5ms' : '0ms',
                                }}
                                unmountOnExit
                              >
                                <CircularProgress color="error"/>
                              </Fade>
                            )}
                          </Box>
                        </Box>
                </DialogTitle>
                       

              
           </Dialog>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, cart)}
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <h4 style={{ color: "#e32740a5" }}>{errorMesage}</h4>
                </Box>
                <Box
                  visibility={activeStep === 1 ? "hidden" : "visible"}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {activeStep !== 0 && (
                    <Button
                      style={{ backgroundColor: "#E32740" ,color:"white"}}
                      onClick={handleBack}
                      sx={{ mt: 3, ml: 1 }}
                    >
                      Anterior
                    </Button>
                  )}

                  <Button
                    style={{ backgroundColor: "#E32740" }}
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 3, ml: 1 }}
                  >
                    {activeStep === steps.length - 1
                      ? "Realizar Orden"
                      : "Siguiente"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </Container>
    </ThemeProvider>
  );
}
