import React, { useEffect, useContext, useReducer } from "react";
import axios from "axios";
import authHeader from "../services/auth-header";
import reducer from "../reducers/cart_reducer";
import { baseUrl as url } from "../utils/constants";
import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  TOGGLE_CART_ITEM_AMOUNT,
  CLEAR_CART,
  COUNT_CART_TOTALS,
  ADD_TO_CART_ALL
} from "../actions";

const getLocalStorage = () => {
  let cart = localStorage.getItem("cart");
  console.log(cart);
  if (cart) {
    return JSON.parse(localStorage.getItem("cart"));
  } else {
    return [];
  }
};

const initialState = {
  cart: getLocalStorage(),
  total_items: 0,
  total_amount: 0,
  shipping_fee: 5.34,
};

const CartContext = React.createContext();

export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // add to cart
  const addToCart = async (id, color, amount, product,userid) => {
    try {
      const formData = new FormData();

      const newItem = {
        id: product.id,
        name: product.name,
        color: color,
        amount: amount,
        image: product.img.split(",")[0],
        price: product.price,
        max: product.stock,
      };


    formData.append(
      'cart',
      new Blob([JSON.stringify(newItem)], {
        type: 'application/json',
      })
    );


      const response = await axios.post(url + "api/shoppingCart/addToCar"+"/"+userid,formData, { headers: authHeader() });
      dispatch({ type: ADD_TO_CART, payload: { id, color, amount, product } });
    } catch (error) {
      dispatch({ type: ADD_TO_CART, payload: { id, color, amount, product } });
      //dispatch({ type: GET_PRODUCTS_ERROR })
    }
  };
  // remove item
  const removeItem = async (id,userid) => {
    try {
      console.log(userid);
      const response = await axios
      .delete(url + "api/shoppingCart/removeFromCart"+"/"+userid+"/"+id, { headers: authHeader() });
      
      dispatch({ type: REMOVE_CART_ITEM, payload: id });
    } catch (error) {
    //  dispatch({ type: REMOVE_CART_ITEM, payload: id });
      //dispatch({ type: GET_PRODUCTS_ERROR })
    }
    
  };


  const getCartItems = async (userid) => {
    try {
      console.log(userid);
      const response = await axios
      .get(url + "api/shoppingCart/get/user/"+userid, { headers: authHeader() });
      dispatch({ type: ADD_TO_CART_ALL, payload: response.data });
    } catch (error) {
    //  dispatch({ type: REMOVE_CART_ITEM, payload: id });
      //dispatch({ type: GET_PRODUCTS_ERROR })
    }
    
  };



  // toggle amount
  const toggleAmount = (id, value) => {
    dispatch({
      type: TOGGLE_CART_ITEM_AMOUNT,
      payload: {
        id,
        value,
      },
    });
  };
  // clear cart
  const clearCart = () => {
    dispatch({ type: CLEAR_CART });
  };


  //run only once
  useEffect(() => {
    
    var user = localStorage.getItem("user");
    if(user != undefined){
      var userObj = JSON.parse(user);
      getCartItems(userObj.id);
    }
  }, []);


  useEffect(() => {

    
    //localStorage.setItem("cart", JSON.stringify(state.cart));
    //console.log("cart items");
    dispatch({ type: COUNT_CART_TOTALS });
  }, [state.cart]);
  return (
    <CartContext.Provider
      value={{ ...state, addToCart, removeItem, toggleAmount, clearCart }}
    >
      {children}
    </CartContext.Provider>
  );
};
// make sure use
export const useCartContext = () => {
  return useContext(CartContext);
};
