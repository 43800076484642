import React from "react";
import styled from "styled-components";
import { formatPrice } from "../utils/helpers";
import { Link } from "react-router-dom";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import { baseUrl } from "../utils/constants";

const OrderList = ({ orders }) => {
  const theme = useTheme();
  return (
    <Wrapper>
      {orders.map((order) => {
        const { id, address, dateAndTime, name, status } = order;
        return (
          <Card sx={{ display: "flex", ml: 2, mr: 2 }}>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={baseUrl
                .concat("product-images/order_img.png")}
              alt="Live from space album cover"
            />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography component="div" variant="h5">
                  {name}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  component="div"
                >
                  {address}
                </Typography>
                <Typography aria-label="next" variant="h5">
                  {status === 1
                    ? "Comprado"
                    : status === 2
                    ? "En revision"
                    : status === 3
                    ? "En Camino"
                    : "Entregado"}
                </Typography>
              </CardContent>
              <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
                <Link to={`/getOrder/${id}`} className="btn">
                  <Typography aria-label="next" variant="h5">
                    Detalles
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Card>
          /*
          <article key={id}>
            <div>
              <h4>{address}</h4>
              <h5 >{dateAndTime}</h5>
              <p>{name}</p>
              <p className='price'>{status === 1 ? 'Comprado' : status === 2 ? 'En revision' : status === 3 ? 'En Camino' : 'Entregado'}</p>
              <Link to={`/getOrder/${id}`} className='btn'>
                Detalles
              </Link>
            </div>
          </article>
          */
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.section`
  display: grid;
  row-gap: 3rem;

  img {
    width: 100%;
    display: block;
    width: 300px;
    height: 200px;
    object-fit: cover;
    border-radius: var(--radius);
    margin-bottom: 1rem;
  }
  h4 {
    margin-bottom: 0.5rem;
  }
  .price {
    color: var(--clr-primary-6);
    margin-bottom: 0.75rem;
  }
  p {
    max-width: 45em;
    margin-bottom: 1rem;
  }
  .btn {
    font-size: 0.5rem;
    padding: 0.25rem 0.5rem;
  }
  @media (min-width: 992px) {
    article {
      display: grid;
      grid-template-columns: auto 1fr;
      column-gap: 2rem;
      align-items: center;
    }
  }
`;

export default OrderList;
