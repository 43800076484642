import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/AccountBox";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { validateEmail } from "../utils/helpers";
import { baseUrl } from "../utils/constants";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import {AlertTitle, Dialog, DialogTitle, DialogContent, Fade } from "@mui/material";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Resuelve aqui
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {
  const [open, setOpen] = React.useState(false);
  
  const [openLoading, setOpenLoading] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");

  const [userName, setUserName] = React.useState("");

  const [lastName, setLastName] = React.useState("");

  const [email, setEmail] = React.useState("");

  const [password, setPassword] = React.useState("");

  const [repeatPassword, setRepeatPassword] = React.useState("");

  const [promotions, setPromotions] = React.useState("");

  const [address, setAddress] = React.useState("");
  
  const [errorMsg, setErroMsg] = React.useState("");

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleAddress = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setAddress(value);
  };
  const handleFirstName = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setFirstName(value);
  };
  const handleUserName = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setUserName(value);
  };
  const handleLastName = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setLastName(value);
  };
  const handleEmail = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setEmail(value);
  };
  const handlePassword = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setPassword(value);
  };
  const handleRepeatPassword = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setRepeatPassword(value);
  };
  const handleCheckBox = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setPromotions(value);
  };
  const handleRegister = (event) => {
    event.preventDefault();
    if (!validateEmail(email)) {
      console.log("Check email format");
      setErroMsg("El email tiene un formato incorrecto");
      handleClick();
      return;
    }
    if (repeatPassword != password) {
      console.log("Password and repeat password should be equal");
      setErroMsg("El campo contraseña y repetir contraseña deben ser iguales");
      handleClick();
      return;
    }
    if (password === "") {
      console.log("Password can not be empty");
      setErroMsg("El campo contraseña no puede estar vacío");
      handleClick();
      return;
    }
    if (password.length < 8) {
      console.log("La contraseña debe tener almeno 8 caracteres");
      setErroMsg("La contraseña debe tener almeno 8 caracteres");
      handleClick();
      return;
    }
    if (address === "") {
      console.log("Address can not be empty");
      setErroMsg("EL campo dirección no puede estar vacío");
      handleClick();
      return;
    }
    
    if (userName.length < 3) {
      console.log("El nombre se usuario debe tener almeno 3 caracteres ");
      setErroMsg("El nombre se usuario debe tener almeno 3 caracteres ");
      handleClick();
      return;
    }

    setOpenLoading(true);

    const userJson = {
      firstname: firstName,
      lastname: lastName,
      username: userName,
      password: password,
      email: email,
      address:address,
      rol: "1",
    };

    axios
      .post(baseUrl.concat("api/auth/signup"), userJson)
      .then((res) => {
        setOpenLoading(false);
        console.log(res);
        window.location.href = "/signin";
        //  redirect to products page
      })
      .catch((err) => {
        setOpenLoading(false);
        console.log(err);
      });
      
    console.log("All good");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={errorMsg === "" ? "success" : "error"} sx={{ width: "100%" }}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <Container component="main" maxWidth="xs">
      <Dialog open={openLoading}>
            <DialogTitle>
            <CircularProgress color="error"/>
            </DialogTitle>
          </Dialog>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#E32740" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  onChange={handleFirstName}
                  value={firstName}
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  onChange={handleLastName}
                  value={lastName}
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  onChange={handleUserName}
                  value={userName}
                  id="username"
                  label="User Name"
                  name="username"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={handleEmail}
                  value={email}
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={handlePassword}
                  value={password}
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={handleRepeatPassword}
                  value={repeatPassword}
                  name="repeatPassword"
                  label="Repeat Password"
                  type="password"
                  id="repeatPassword"
                  autoComplete="repeat-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={handleAddress}
                  value={address}
                  name="address"
                  label="Address"
                  id="address"
                  autoComplete="address"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={handleCheckBox}
                      value={promotions}
                      color="primary"
                    />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button
              onClick={handleRegister}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="signin" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
