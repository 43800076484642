import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import AuthService from "../../services/auth.service";

const products_old = [
  {
    name: "Product 1",
    desc: "A nice thing",
    price: "$9.99",
  },
  {
    name: "Product 2",
    desc: "Another thing",
    price: "$3.45",
  },
  {
    name: "Product 3",
    desc: "Something else",
    price: "$6.51",
  },
  {
    name: "Product 4",
    desc: "Best thing of all",
    price: "$14.11",
  },
  { name: "Shipping", desc: "", price: "Free" },
];

const addresses = ["1 MUI Drive", "Reactville", "Anytown", "99999", "USA"];
const payments = [
  { name: "Tipo:", detail: "Visa" },
  { name: "Titular:", detail: "Mr John Smith" },
  { name: "Número:", detail: "xxxx-xxxx-xxxx-1234" },
  { name: "Caducidad:", detail: "04/2024" },
];

export default function Review({ products }) {
  const currentUser = AuthService.getCurrentUser();
  var total = 0;
  products.map((product) => (total = (total + product.price) * product.amount));

  React.useEffect(() => {
    console.log(currentUser);
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
      Resumen del pedido
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.id} sx={{ py: 1, px: 0 }}>
            <ListItemText
              primary={"Nombre: " + product.name}
              secondary={"Cantidad: " + product.amount}
            />
            <Typography variant="body2">
              {"$"}
              {product.price * product.amount}
            </Typography>
          </ListItem>
        ))}

        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {"$"}
            {total}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Transporte
          </Typography>
          <Typography gutterBottom>{currentUser.firstname} {currentUser.lastname}</Typography>
          <Typography gutterBottom>{currentUser.address}</Typography>
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          Detalles del pago
          </Typography>
          <Grid container>
            {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={4}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
