import React, { useState ,useEffect} from "react";
import styled from "styled-components";
const ProductDetails = ({ product }) => {
    const [details, setDetails] = useState([])

 // const { details } = product;
 
  const detailsArray = []//JSON.parse(details);


  useEffect(() => {
   
   
    if(product){
        setDetails(JSON.parse(product));
    }
    // eslint-disable-next-line
  }, [])


  return (
    <Wrapper>
      <h3>Más detalles </h3>
      {details.map((detail) => {
        const { key, value } = detail;
        return (
          <p className="info">
            <span>{key}:</span>
            {value}
          </p>
        );
      })}
    </Wrapper>
  );
};
const Wrapper = styled.section``;

export default ProductDetails;
