import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import authHeader from "../services/auth.service";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { validateEmail } from "../utils/helpers";
import axios from "axios";
import { baseUrl } from "../utils/constants";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Resuelve aqui
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ResetPasswordPage() {
  const [open, setOpen] = React.useState(false);
  const form = React.useRef();
  const checkBtn = React.useRef();
  const [emailAddress, setEmailAddress] = React.useState("");
  const [errorMsg, setErroMsg] = React.useState("");
  const [sucessMsg, setSucessMsg] = React.useState("");
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleEmailAddress = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setEmailAddress(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (emailAddress === "") {
      setErroMsg("El campo de email no puede estar vacio");
      setSucessMsg("");
      handleClick();
      return;
    }
    if (!validateEmail(emailAddress)) {
      setErroMsg("El email tiene un formato incorrecto");
      setSucessMsg("");
      handleClick();
      return;
    }

    var emailDto = {
      mailTo: "albertocarlosmartin@gmail.com",
      tokenPassword: "",
    };

    axios
      .post(baseUrl + "api/users/reset-password", emailDto, { headers: authHeader() })
      .then((response) => {
        setSucessMsg("Contraseña restaurada, revise su email");
        setErroMsg("");
        handleClick();
      }).catch(err => {
        setErroMsg(err);
    });
  };

  return (
    <ThemeProvider theme={theme}>
      ç
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity={errorMsg === "" ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {errorMsg}{sucessMsg}
        </Alert>
      </Snackbar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#E32740" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Restaurar contraseña
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="emailaddress"
              value={emailAddress}
              onChange={handleEmailAddress}
              label="Email"
              name="email"
              type="text"
              autoComplete="email"
              autoFocus
            />

            <Button
              onClick={handleSubmit}
              fullWidth
              style={{ backgroundColor: "#E32740" }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Enviar por email
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
