import axios from "axios";
import { baseUrl as url } from "../utils/constants";
const register = (username, email, password) => {
  return axios.post(url + "api/auth/signup", {
    username,
    email,
    password,
  });
};
const login = (username, password) => {
  return axios
    .post(url + "api/auth/signin", {
      username,
      password,
    })
  //  .auth(this.state.id_token, { type: 'bearer' })
    //.set('Authorization', 'Bearer ' + this.state.id_token)
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("user", JSON.stringify(response.data));
       axios
          .get(url + "api/shoppingCart/get/user/"+response.data.id, {
            username,
            password,
          })
          .then((response) => {
              console.log(response.data);
              localStorage.setItem('cart', response.data.products)
          });
        //  console.log(response.data);
        //  localStorage.setItem('cart', JSON.stringify(JSON.stringify(response.data).shoppingCar.products))
      }
      return response.data;
    });
};
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
};
const getCurrentUser = () => {
  if(localStorage.getItem("user")){
    return JSON.parse(localStorage.getItem("user"));
  }else{
    localStorage.removeItem("user");
    return undefined;
  }
};
const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
};
export default AuthService;
