import React from 'react'

const LoadingProducts = () => {
  return (
    <div className='section secton-center'>
      <div className='loading-products'></div>
    </div>
  )
}

export default LoadingProducts
