import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthService from "../services/auth.service";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Resuelve aqui
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ForgotPassworddPage() {
  const form = React.useRef();
  const checkBtn = React.useRef();
  const [currentPassword, setCurrentPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [repeatNewPassword, setRepeatNewPassword] = React.useState("");

  const handleCurrentPassword = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setCurrentPassword(value);
  };
  const handleNewPassword = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setNewPassword(value);
  };
  const handleRepeatNewPassword = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setRepeatNewPassword(value);
  };
  const handleLogin = (e) => {
    e.preventDefault();
    
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "#E32740" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Recuperar contraseña
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="currentpassword"
              value={currentPassword}
              onChange={handleCurrentPassword}
              label="Contraseña actual"
              name="password"
              type="password"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              value={newPassword}
              onChange={handleNewPassword}
              name="password"
              label="Nueva contraseña:"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              value={repeatNewPassword}
              onChange={handleRepeatNewPassword}
              name="password"
              label="Introduce tu nueva contraseña nuevamente"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              onClick={handleLogin}
              fullWidth
              style={{ backgroundColor: "#E32740" }}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Guardar
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
