import React from 'react'
import { GiCompass, GiDiamondHard, GiStabbedNote } from 'react-icons/gi'
export const links = [
  {
    id: 1,
    text: 'Inicio',
    url: '/',
  },
  {
    id: 3,
    text: 'Productos',
    url: '/products',
  },
  {
    id: 3,
    text: 'Pregutas Frecuentes',
    url: '/about',
  },
 
]

export const services = [
  {
    id: 1,
    icon: <GiCompass />,
    title: 'mission',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, ea. Perferendis corrupti reiciendis nesciunt rerum velit autem unde numquam nisi',
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: 'vision',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, ea. Perferendis corrupti reiciendis nesciunt rerum velit autem unde numquam nisi',
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: 'history',
    text:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptates, ea. Perferendis corrupti reiciendis nesciunt rerum velit autem unde numquam nisi',
  },
]

export const products_url = 'https://resuelveaqui.com:8443/api/products/all_productos'
//export const products_url = 'http://localhost:8080/api/products/all_productos'
// export const categories_url = 'http://localhost:8080/api/category/categories'
 export const categories_url = 'https://resuelveaqui.com:8443/api/category/categories'
//export const search_url = 'http://localhost:8080/api/products/search'
 export const search_url = 'https://resuelveaqui.com:8443/api/products/search'
//export const single_product_url =  'https://course-api.com/react-store-single-product?id='
export const single_product_url = 'https://resuelveaqui.com:8443/api/products/get/'
//export const single_product_url = 'http://localhost:8080/api/products/get/'
export const baseUrl = 'https://resuelveaqui.com:8443/'
//export const baseUrl = 'http://localhost:8080/'