import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { red } from '@mui/material/colors';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function AddressForm(props) {
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Dirección de envío
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="FirstField"
            name="firstName"
            label="Nombre"
            //value={props.model.FirstField || ""}
            onChange={props.handleAddressFirstName}
            fullWidth
            autoComplete="given-name"
            variant="standard"
            color="error"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Apellido"
            onChange={props.handleAddressLastName}
            fullWidth
            autoComplete="family-name"
            variant="standard"
            color="error"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address1"
            name="address"
            label="Dirección"
            onChange={props.handleAddress}
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            color="error"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="address2"
            name="address2"
            label="Teléfono"
            onChange={props.handlePhone}
            fullWidth
            autoComplete="shipping address-line2"
            variant="standard"
            color="error"
          />
        </Grid>
        
        <FormControl variant="standard" sx={{ ml: 3,mt:3, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">Provincia</InputLabel>
        <Select 
          labelId="demo-simple-select-standard-label"
          id="state"
          name="state"
          value={props.shippingState}
          onChange={props.handleState}
          label="Provincia"
          fullWidth
          variant="standard"
          color="error"
        >
          <MenuItem value={'La Habana'}>La Habana</MenuItem>
        </Select>
      </FormControl>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            onChange={props.handleZip}
            label="Código postal"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
            color="error"
          />
        </Grid>

        <FormControl variant="standard" sx={{ ml: 3,mt:3, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-standard-label">País</InputLabel>
        <Select 
          labelId="demo-simple-select-standard-label"
          id="country"
          name="country"
          value={props.shippingCountry}
          onChange={props.handleCountry}
          label="País"
          fullWidth
          variant="standard"
          color="error"
          utoComplete="shipping country"
        >
          <MenuItem value={'Cuba'}>Cuba</MenuItem>
        </Select>
      </FormControl>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox color="primary" name="saveAddress" value="yes" sx={{
                color: red[800],
                '&.Mui-checked': {
                  color: red[600],
                },
              }}/>
            }
            label="Use esta dirección para detalles de pago"
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
