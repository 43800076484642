import {
    GET_CATEGORIES_BEGIN,
    GET_CATEGORIES_SUCCESS,
  } from '../actions'
  
  const category_reducer = (state, action) => {
    if (action.type === GET_CATEGORIES_BEGIN) {
      return { ...state}
    }

    if (action.type === GET_CATEGORIES_SUCCESS) {
      return {
        ...state,
        categories: action.payload
      }
    }
    //throw new Error(`No Matching "${action.type}" - action type`)
  }
  
  export default category_reducer
  