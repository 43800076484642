export const SIDEBAR_OPEN = 'SIDEBAR_OPEN'
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE'
export const FILTER_SIDEBAR_OPEN = 'FILTER_SIDEBAR_OPEN'
export const FILTER_SIDEBAR_CLOSE = 'FILTER_SIDEBAR_CLOSE'
export const GET_PRODUCTS_BEGIN = 'GET_PRODUCTS_BEGIN'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_CATEGORIES_BEGIN = 'GET_PRODUCTS_BEGIN'
export const GET_CATEGORIES_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'
export const GET_SINGLE_PRODUCT_BEGIN = 'GET_SINGLE_PRODUCT_BEGIN'
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS'
export const GET_SINGLE_PRODUCT_ERROR = 'GET_SINGLE_PRODUCT_ERROR'
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'
export const SET_GRIDVIEW = 'SET_GRIDVIEW'
export const SET_LISTVIEW = 'SET_LISTVIEW'
export const UPDATE_SORT = 'UPDATE_SORT'
export const SORT_PRODUCTS = 'SORT_PRODUCTS'
export const UPDATE_FILTERS = 'UPDATE_FILTERS'
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const ADD_TO_CART = 'ADD_TO_CART'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const ADD_TO_CART_ALL = 'ADD_TO_CART_ALL'
export const TOGGLE_CART_ITEM_AMOUNT = 'TOGGLE_CART_ITEM_AMOUNT'
export const CLEAR_CART = 'CLEAR_CART'
export const COUNT_CART_TOTALS = 'COUNT_CART_TOTALS'
export const TOTAL_PAGES = 'TOTAL_PAGES'
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING'
export const GET_OPTION_VALUE = 'GET_OPTION_VALUE'

