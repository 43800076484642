import React from 'react'
import styled from 'styled-components'
import { useFilterContext } from '../context/filter_context'
import { getUniqueValues, formatPrice } from '../utils/helpers'
import { FaCheck } from 'react-icons/fa'
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { red } from '@mui/material/colors';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useCategoryContext } from "../context/category_context";
import { useProductsContext } from "../context/products_context";
import TextField from "@mui/material/TextField";
import { useHistory } from 'react-router';
import Button from "@mui/material/Button";

const Filters = () => {
  
  const {
    filters: {
      text,
      category,
      company,
      color,
      min_price,
      price,
      max_price,
      shipping,
    },
    updateFilters,
    all_products,
    clearFilters,
  } = useFilterContext()
  const { openSidebar, openFilterSidebar, filterProductsBySearch,setOptionValue } =
  useProductsContext();


  const companies = getUniqueValues(all_products, 'company')
  const colors = getUniqueValues(all_products, 'colors')
  const { categories } = useCategoryContext();
  const routerHistory = useHistory();

const [age2, setAge2] = React.useState("todos");

const handleChange2 = async (event) => {
  setAge2(event.target.value);
  var button = document.getElementById("action-button");
  await new Promise(r => setTimeout(r, 100));
  setOptionValue(event.target.value)
  button.click();
  
};
function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}



  return (
    <Wrapper>
      <div className='content'>
      <div id="select-category">
        <form id="form1" onSubmit={filterProductsBySearch}>
          <Select  color="success" 
          sx={{ minWidth: 120 }}
          size="small"
          style={{
            borderRadius: "0px",
            width: "6em",
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age2}
          defaultValue={age2}
          onChange={handleChange2}
        >
          <MenuItem  value={"todos"}>Todos</MenuItem>
          {categories?.map((category) => {
            const { id, type } = category;
            return (
              <MenuItem 
              onClick={(e)=>{
                //routerHistory.push('/products');
                submitForm(type)
              }}
              key={id} value={type}>
                {type}
              </MenuItem>
            );
          })}
        </Select>
        
                <Button
                id="action-button"
          type="submit"
          onClick={()=>{

          }}
          aria-label="search"
          style={{ borderRadius: "0px", backgroundColor: "#E32740" ,visibility:'hidden'}}
        >
        </Button>
        </form>
        </div>


        <form onSubmit={filterProductsBySearch}>
          {/* search input */}
           {/* }
          <div className='form-control'>
            <input
              type='text'
              name='text'
              value={text}
              placeholder='buscar'
              onChange={updateFilters}
              className='search-input'
            />
          </div>
           {*/}
          {/* end of search input */}
          {/* category */}
           {/*<div className='form-control'>
            <h5>categoría</h5>
            <div>
              {categories.map((c, index) => {
                return (
                  <button
                    key={index}
                    onClick={updateFilters}
                    type='button'
                    name='category'
                    className={`${
                      category === c.toLowerCase() ? 'active' : null
                    }`}
                  >
                    {c}
                  </button>
                )
              })}
            </div>
          </div>*/}
          {/* end of category */}
          {/* company */}
          {/*<div className='form-control'>
            <h5>compañía</h5>
            <select
              name='company'
              value={company}
              onChange={updateFilters}
              className='company'
            >
              {companies.map((c, index) => {
                return (
                  <option key={index} value={c}>
                    {c}
                  </option>
                )
              })}
            </select>
          </div>*/}
          {/* end of company */}
          {/* colors */}


          {/* <div className='form-control'>
            <h5>colors</h5>
            <div className='colors'>
              {colors.map((c, index) => {
                if (c === 'all') {
                  return (
                    <button
                      key={index}
                      name='color'
                      onClick={updateFilters}
                      data-color='all'
                      className={`${
                        color === 'all' ? 'all-btn active' : 'all-btn'
                      }`}
                    >
                      all
                    </button>
                  )
                }
                return (
                  <button
                    key={index}
                    name='color'
                    style={{ background: c }}
                    className={`${
                      color === c ? 'color-btn active' : 'color-btn'
                    }`}
                    data-color={c}
                    onClick={updateFilters}
                  >
                    {color === c ? <FaCheck /> : null}
                  </button>
                )
              })}
            </div>
          </div> */}


          {/* end of colors */}
          {/* price */}

         


         {/*    <div className='form-control'>
            <h5>precio</h5>

            <Typography id="non-linear-slider" gutterBottom>
            Hasta: {formatPrice(price)}
          </Typography>
          <Slider
            value={price}
            type='range'
            name='price'
            onChange={updateFilters}
            onBlur={min_price}
            min={min_price}
            max={max_price}
            color="error"
           
          /> 
           
            {/* <p className='price'>{formatPrice(price)}</p> */}
           
            {/* <input
              type='range'
              name='price'
              onChange={updateFilters}
              min={min_price}
              max={max_price}
              value={price}
              color="secondary"
             
            /> 
          </div>*/}
          {/* end of price */}
          {/* shipping */}
          {/* <div className='form-control shipping'>
            <label htmlFor='shipping'>envío</label>

            <input
              type='checkbox'
              name='shipping'
              id='shipping'
              checked={shipping}
              onChange={updateFilters}
            />
          </div> */}
         {/* <div className='form-control shipping'>
             <FormControlLabel
              value="start"
              control={<Checkbox  sx={{
                color: red[800],
                '&.Mui-checked': {
                  color: red[700],
                },
              }} />}
              label="Envío"
              labelPlacement="start"
              name='shipping'
              id='shipping'
              checked={shipping}
              onChange={updateFilters}
            />
          </div>*/}


          {/* end of  shipping */}
        </form>
      {/*   <button type='button' className='clear-btn' onClick={clearFilters}>
          Limpiar
        </button>*/}
      </div>
    </Wrapper>
  )


  function submitForm(type){
    var button = document.getElementById("action-button");
    var select = document.getElementById("demo-simple-select");
    if(button){
      //select.value = type;
      //button.click();
    }
   }

}

const Wrapper = styled.section`
  .form-control {
    margin-bottom: 1.25rem;
    h5 {
      margin-bottom: 0.5rem;
    }
  }
  .search-input {
    padding: 0.5rem;
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    letter-spacing: var(--spacing);
  }
  .search-input::placeholder {
    text-transform: capitalize;
  }

  button {
    display: block;
    margin: 0.25em 0;
    padding: 0.25rem 0;
    text-transform: capitalize;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-5);
    cursor: pointer;
  }
  .active {
    border-color: var(--clr-grey-5);
  }
  .company {
    background: var(--clr-grey-10);
    border-radius: var(--radius);
    border-color: transparent;
    padding: 0.25rem;
  }
  .colors {
    display: flex;
    align-items: center;
  }
  .color-btn {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #222;
    margin-right: 0.5rem;
    border: none;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      font-size: 0.5rem;
      color: var(--clr-white);
    }
  }
  .all-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    opacity: 0.5;
  }
  .active {
    opacity: 1;
  }
  .all-btn .active {
    text-decoration: underline;
  }
  .price {
    margin-bottom: 0.25rem;
  }
  .shipping {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    text-transform: capitalize;
    column-gap: 0.5rem;
    font-size: 1rem;
  }
  .clear-btn {
    background: var(--clr-red-dark);
    color: var(--clr-white);
    padding: 0.25rem 0.5rem;
    border-radius: var(--radius);
  }
  @media (min-width: 768px) {
    .content {
      position: sticky;
      top: 1rem;
    }
  }
  
`

export default Filters
