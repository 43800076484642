import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Navbar, Sidebar, Footer, FilterSidebar,SearchBar } from './components'
import {
  Home,
  SingleProduct,
  Cart,
  Checkout,
  Error,
  About,
  Products,
  PrivateRoute,
  AuthWrapper,
  SignIn,
  SignUp,
  ProfilePage,
  ChangePassword,
  Orders,
  OrderDetail,
  ResetPassword
} from './pages'


function App() {


  return (
    /*
    <AuthWrapper>

    */
      <Router>
        <Navbar />
        <SearchBar />
        <Sidebar />
        <FilterSidebar />
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route path='/about'>
            <About />
          </Route>
          <Route path='/cart'>
            <Cart />
          </Route>
          <Route path='/signin'>
            <SignIn />
          </Route>
          <Route path='/signup'>
            <SignUp />
          </Route>
          <PrivateRoute path='/profile'>
            <ProfilePage />
          </PrivateRoute>
          <Route path='/changepassword'>
            <ChangePassword />
          </Route>
          <Route path='/resetpassword'>
            <ResetPassword />
          </Route>
          <Route exact path='/products'>
            <Products />
          </Route>
          <PrivateRoute exact path='/orders'>
            <Orders />
          </PrivateRoute>
          <Route path='/getOrder/:id' children={<OrderDetail />} />
          <Route path='/products/:id' children={<SingleProduct />} />
          <PrivateRoute path='/checkout'>
            <Checkout />
          </PrivateRoute>
          <Route path='*'>
            <Error />
          </Route>
        </Switch>
        <Footer />
      </Router>/*
    </AuthWrapper>*/
    
  )
}

export default App
