import React from "react";
import styled from "styled-components";
import {
  PageHero,
  StripeCheckout,
  StripeForm,
  Checkout,
  CheckoutForm,
} from "../components";

// extra imports
//  https://github.com/mui/material-ui/tree/v5.8.2/docs/data/material/getting-started/templates/checkout
import { useCartContext } from "../context/cart_context";
import { Link } from "react-router-dom";

const CheckoutPage = () => {
  const { cart } = useCartContext();


  return (
    <main>
      <PageHero title="Verificar" />
      <Wrapper className="page">
        {cart.length < 1 ? (
          <div className="empty">
            <h2>Tu carrito esta vacío</h2>
            <Link to="/products" className="btn">
            llenalo
            </Link>
          </div>
        ) : (
          <div className="empty">
            <Checkout />
          </div>

          /*<StripeForm /> */
        )}
      </Wrapper>
    </main>
  );
};
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .empty {
    text-align: center;
  }
  /**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;
export default CheckoutPage;
