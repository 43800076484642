import * as React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { validateEmail } from "../utils/helpers";
import { baseUrl } from "../utils/constants";
import axios from "axios";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="#">
        Resuelve aqui
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function ProfilePage() {
  const [open, setOpen] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");

  const [userName, setUserName] = React.useState("");

  const [lastName, setLastName] = React.useState("");

  const [email, setEmail] = React.useState("");

  const [address, setAddress] = React.useState("");

  const currentUser = AuthService.getCurrentUser();

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFirstName = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setFirstName(value);
  };
  const handleUserName = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setUserName(value);
  };
  const handleLastName = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setLastName(value);
  };
  const handleEmail = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setEmail(value);
  };
  const handlAddress = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    setAddress(value);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    const userJson = {
      id: currentUser.id,
      firstname: firstName,
      lastname: lastName,
      username: userName,
      address:address,
      password: "$2a$10$5wtdIiXJOD7wt/WPLp0r/OodOkXr2Fpiqe9Ab5NMX8ZQnj9Y/qTiq",
      email: email,
      rol: "1",
    };
    UserService.updateUser(userJson).then(
      () => {
        handleClick();
        //navigate("/profile");
        //window.location.href = "/";
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log(resMessage);
      }
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  React.useEffect(() => {
    UserService.getUser(currentUser.id).then(
      (response) => {
        setEmail(response.email);
        setUserName(response.username);
        setFirstName(response.firstname);
        setLastName(response.lastname);
        setAddress(response.address)
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          if(error.response.status == 401){
            //window.location.href = "/signin";
          }
      }
    );


  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          El usuario fue actualizado
        </Alert>
      </Snackbar>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Perfil
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  onChange={handleFirstName}
                  value={firstName}
                  required
                  fullWidth
                  id="firstName"
                  label="Nombre"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  onChange={handleLastName}
                  value={lastName}
                  id="lastName"
                  label="Apellido"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  onChange={handleUserName}
                  value={userName}
                  id="username"
                  label="Nombre de usuario"
                  name="username"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={handleEmail}
                  value={email}
                  id="email"
                  label="Correo Electrónico "
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  onChange={handlAddress}
                  value={address}
                  id="email"
                  label="Dirección de entrega"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
            </Grid>
            <Button
              style={{ backgroundColor: "#E32740" }}
              onClick={() => {
                window.location.href = "/changepassword";
              }}
              to="/changepassword"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Cambiar contraseña
            </Button>
            <Button
              style={{ backgroundColor: "#E32740" }}
              onClick={handleUpdate}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Actualizar
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
