import React from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Box from "@mui/material/Box";
import { CardSection } from "../components";
import Button from "@mui/material/Button";
export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();


  async function stripeTokenHandler(token) {
    const paymentData = { token: token.id };

    // Use fetch to send the token ID and any other payment data to your server.
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    const response = await fetch("/charge", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(paymentData),
    });

    // Return and display the result of the charge.
    return response.json();
  }
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    if (result.error) {
      // Show error to your customer.
      //console.log(result.error.message);
      props.handleCardError(result.error.message);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      props.handleCardError(null);
      props.handleSubmitPayment(result.token);
      //stripeTokenHandler(result.token);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div disabled={!stripe}>
        <CardSection />
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button style={{backgroundColor:"#E32740"}} type="submit" variant="contained" sx={{ mt: 3, ml: 1 }}>
            Validar Tarjeta
          </Button>
        </Box>
      </div>

      {/*<button disabled={!stripe}>Confirm order</button> */}
    </form>
  );
}
