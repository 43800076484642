import React from "react";
import styled from "styled-components";
import { Filters, ProductList, Sort, PageHero } from "../components";


const ProductsPage = () => {

  return (
    <main>
      <PageHero title="productos" />
      <Wrapper className="page">
        <div className="section-center products">
          <div className="filter-component">
         
        <br></br>
            <Filters />
          </div>
          <div>
            <Sort />
            <ProductList />
          </div>
        </div>
      </Wrapper>
    </main>
  );
  
};

const Wrapper = styled.div`
  .products {
    display: grid;
    gap: 1rem 1.5rem;
    margin: 1rem auto;
  }
  @media (min-width: 768px) {
    .products {
      grid-template-columns: 200px 1fr;
    }
  }
  #@media (max-width: 448px) {
  #  .filter-component {
  #    display: none;
  #  }
  #}
`;

export default ProductsPage;
