import React from "react";
import { useFilterContext } from "../context/filter_context";
import GridView from "./GridView";
import ListView from "./ListView";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { LoadingProducts } from "../components";
import { useProductsContext } from '../context/products_context'
import { products_url as url } from '../utils/constants'

const ProductList = () => {
  const {  getProductsByPage , products_loading} = useProductsContext();
  const { filtered_products: products, grid_view ,totalPages} = useFilterContext();
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    console.log('making pag request for page: '+value);
   //fetchProducts(url,value);
    setPage(value);
  };

  if (products.length < 1) {
    return (
      <h5 style={{ textTransform: "none" }}>
        Lo sentimos, no hay productos que coincidan con su búsqueda.
      </h5>
    );
  }

  if (grid_view === false) {
    return <ListView products={products} />;
  }
  return (
 
    <div>
      <div hidden={!products_loading}>
      <LoadingProducts />
      </div>
  
      <GridView products={products} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack spacing={2}>
          {/*<Typography>Page: {page}</Typography>*/}
          <br/>
          {/*<Pagination count={totalPages} page={currentPage} onChange={getProductsByPage} />*/}
          <Pagination count={totalPages} onChange={getProductsByPage} />
        </Stack>
      </Box>
    </div>
  );
};

export default ProductList;
