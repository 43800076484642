import React from "react";
import styled from "styled-components";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { FaShoppingCart, FaUserMinus, FaUser } from "react-icons/fa";
import logo from "../assets/logo.png";
import { FaBars, FaFilter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { links } from "../utils/constants";
import CartButtons from "./CartButtons";
import { useCartContext } from "../context/cart_context";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { useProductsContext } from "../context/products_context";
import { useUserContext } from "../context/user_context";
import { useCategoryContext } from "../context/category_context";
import AuthService from "../services/auth.service";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ButtonGroup from "@mui/material/ButtonGroup";
import Paper from "@mui/material/Paper";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { useHistory } from "react-router";
const filterData = (query, data) => {
  if (!query) {
    return data;
  } else {
    return data.filter((d) => d.toLowerCase().includes(query));
  }
};

const data = [
  "Paris",
  "London",
  "New York",
  "Tokyo",
  "Berlin",
  "Buenos Aires",
  "Cairo",
  "Canberra",
  "Rio de Janeiro",
  "Dublin",
];

const SearchBar = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const routerHistory = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const goToLoginPage = () => {
    window.location.href = "/signin";
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOut = () => {
    AuthService.logout();
  };

  const { categories } = useCategoryContext();
  const { total_items, clearCart } = useCartContext();
  const { openSidebar, openFilterSidebar, filterProductsBySearch } =
    useProductsContext();
  const [searchQuery, setSearchQuery] = React.useState("");
  const dataFiltered = filterData(searchQuery, data);
  const [age, setAge] = React.useState("todos");

  const SearchBar = ({ setSearchQuery }) => (
    <form onSubmit={filterProductsBySearch}>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        style={{
          borderRadius: "0px",
        }}
      >
        <Select
          sx={{ minWidth: 120 }}
          size="small"
          style={{
            borderRadius: "0px",
            width: "6em",
            backgroundColor: "#E4E4E4",
            color: "#132A3C",
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          defaultValue={age}
          onChange={handleChange}
        >
          <MenuItem value={"todos"}>Todos</MenuItem>
          {categories?.map((category) => {
            const { id, type } = category;
            return (
              <MenuItem key={id} value={type}>
                {type}
              </MenuItem>
            );
          })}
        </Select>
        <TextField
          name="search"
          size="small"
          id="search-bar"
          className="text"
          onInput={(e) => {
            //  setSearchQuery(e.target.value);
          }}
          variant="outlined"
          placeholder="Buscar..."
          style={{
            borderRadius: "0px",
          }}
        />
        <Button
          type="submit"
          onClick={() => {
            routerHistory.push("/products");
          }}
          aria-label="search"
          style={{ borderRadius: "0px", backgroundColor: "#E32740" }}
        >
          <SearchIcon style={{ fill: "white", fontSize: "2em" }} />
        </Button>
      </ButtonGroup>
    </form>
  );

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  //  const { myUser } = useUserContext();
  const currentUser = AuthService.getCurrentUser();
  return (
    <NavContainer>
      <div className="nav-center">
        <div className="nav-header">
          <div
            className="search-bar nav-user"
            style={{
              alignSelf: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
        </div>
      </div>
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  height: 5rem;
  
  align-items: center;
  justify-content: center;
  .cart-btn {
    color: var(--clr-grey-1);
    font-size: 1.5rem;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-1);
    display: flex;

    align-items: center;
  }
  .cart-container {
    display: flex;
    align-items: center;
    position: relative;
    svg {
      height: 1.6rem;
      margin-left: 5px;
    }
  }
  .cart-value {
    position: absolute;
    top: -10px;
    right: -16px;
    background: var(--clr-ecomerce-5);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.75rem;
    color: var(--clr-white);
    padding: 12px;
  }
  .auth-btn {
    display: flex;
    align-items: center;
    background: transparent;
    border-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--clr-grey-1);
    letter-spacing: var(--spacing);
    svg {
      margin-left: 5px;
    }
  }
  .nav-center {
    width: 90vw;
    margin: 0 auto;
    max-width: var(--max-width);
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 175px;
      margin-left: -15px;
    }
  }
  .nav-toggle {
    background: transparent;
    border: transparent;
    color: var(--clr-menu-button);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }
  .nav-links {
    display: none;
  }
  .cart-btn-wrapper {
    display: none;
  }
  .search-bar {
    display: flex;
  }
  .logo-img {
    margin-left: 10px;
  }
  @media (min-width: 992px) {
    display: none;
    .nav-toggle {
      display: none;
    }

    .nav-user {
      display: none;
    }
    .search-bar {
      display: none;
    }
    .logo-img {
      margin-left: 0px;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .nav-links {
      display: flex;
      justify-content: center;
      li {
        margin: 0 0.5rem;
      }
      a {
        color: var(--clr-grey-3);
        font-size: 1rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        &:hover {
          border-bottom: 2px solid var(--clr-ecomerce-5);
        }
      }
    }
    .cart-btn-wrapper {
      display: grid;
    }
  }
`;

export default SearchBar;
