import React from 'react'
import { useState, useEffect } from 'react';
import styled from 'styled-components'
import { PageHero } from '../components'
import aboutImg from '../assets/hero-bcg.jpeg'
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import axios from 'axios';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { baseUrl as baseUrl } from '../utils/constants';
import authHeader from "../services/auth-header";
const AboutPage = () => {
  const [open, setOpen] = React.useState([]);
  const [data, setData] = React.useState([]);

  const handleClick = (e,id) => {
    const selectedIndex = open.indexOf(id);
    console.log(id);
    console.log(selectedIndex);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(open, id);
    }else if (selectedIndex === 0) {
      newSelected = newSelected.concat(open.slice(1));
    }else if (selectedIndex === open.length - 1) {
      newSelected = newSelected.concat(open.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(open.slice(0, selectedIndex), open.slice(selectedIndex + 1));
    }
    console.log(newSelected)
    setOpen(newSelected);
  };

  useEffect(() => {
    axios
      .get(baseUrl.concat("api/faq/all"),{ headers: authHeader() })
      .then((response) => {
        setData(response.data);
      })
      .catch(console.error);
  }, []);

  
  return (
    <main>
      <PageHero title='Pregutas Frecuetes' />
      <Wrapper className='page section section-center'>
      <section class="preguntas-frecuentes clearfix">
  <div class="preguntas-frecuentes-contenedor serif">

    <div class="preguntas-frecuentes-encabezado-linea-roja"></div>
    <h1 class="preguntas-frecuentes-encabezado">Preguntas Frecuentes</h1>

    <List
      sx={{ width: '100%', maxWidth: 3600, bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      // subheader={
      //   <ListSubheader component="div" id="nested-list-subheader">
      //     Nested List Items
      //   </ListSubheader>
      // }
    >
    {data.map((question) => {
       return(
        <div>
        <ListItemButton onClick={(event) => handleClick(event, question.id)}>
          <ListItemIcon>
            <QuestionMarkIcon />
          </ListItemIcon>
          <ListItemText primary={question.title}/>
          {open.indexOf(question.id) !== -1 ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open.indexOf(question.id) !== -1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 8 }}>
              <ListItemIcon>
                {/* <ArrowRightAltIcon /> */}
              </ListItemIcon>
              <ListItemText primary={question.text} />
            </ListItemButton>
          </List>
        </Collapse>
        </div>
       )
    })}
    </List>

  
  </div>
</section>
      </Wrapper>
    </main>
  )
}
const Wrapper = styled.section`

.preguntas-frecuentes {
  background: #dbe0e8;
  position: relative;
  width: 100%;
  z-index: 89;
}

.preguntas-frecuentes-contenedor {
  margin: 0 auto;
  padding: 4rem 1.25rem 6rem;
  position: relative;
  overflow: hidden;
  width: 80%;
  max-width: 106.25rem;
}

.preguntas-frecuentes-encabezado-linea-roja {
  border-bottom: 2px #d60510 solid;
  margin: 15px auto 21px;
  width: 40px;
}

.preguntas-frecuentes-encabezado {
  font-size: 1.21rem;
  text-align: center;
}
`
export default AboutPage
