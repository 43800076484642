import axios from 'axios'
import React, { useContext, useEffect, useReducer } from 'react'
import reducer from '../reducers/category_reducer'
import { categories_url as url } from '../utils/constants'

import {
    GET_CATEGORIES_BEGIN,
    GET_CATEGORIES_ERROR,
    GET_CATEGORIES_SUCCESS,
} from '../actions'

const initialState = {
  categories: [],
  categories_error: false
}

const CategoryContext = React.createContext()

export const CategoryProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)


  const fetchCategories = async (url) => {
    dispatch({ type: GET_CATEGORIES_BEGIN })
    try {
      const response = await axios.get(url)
      const categories = response.data
      dispatch({ type: GET_CATEGORIES_SUCCESS, payload: categories})
    } catch (error) {
      dispatch({ type: GET_CATEGORIES_ERROR})
    }
  }

  useEffect(() => {
    fetchCategories(url);
  }, [])

  return (
    <CategoryContext.Provider
      value={{
        ...state,
        fetchCategories
      }}
    >
      {children}
    </CategoryContext.Provider>
  )
}
// make sure use
export const useCategoryContext = () => {
  return useContext(CategoryContext)
}
