import React from "react";
import styled from "styled-components";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { FaShoppingCart, FaUserMinus, FaUser } from "react-icons/fa";
import logo from "../assets/logo.png";
import { FaBars, FaFilter } from "react-icons/fa";
import { Link } from "react-router-dom";
import { links } from "../utils/constants";
import CartButtons from "./CartButtons";
import { useCartContext } from "../context/cart_context";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { useProductsContext } from "../context/products_context";
import { useUserContext } from "../context/user_context";
import { useCategoryContext } from "../context/category_context";
import AuthService from "../services/auth.service";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ButtonGroup from "@mui/material/ButtonGroup";
import Paper from "@mui/material/Paper";
import Logout from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import { useHistory } from 'react-router';
const filterData = (query, data) => {
  if (!query) {
    return data;
  } else {
    return data.filter((d) => d.toLowerCase().includes(query));
  }
};

const data = [
  "Paris",
  "London",
  "New York",
  "Tokyo",
  "Berlin",
  "Buenos Aires",
  "Cairo",
  "Canberra",
  "Rio de Janeiro",
  "Dublin",
];

const Nav = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

    const { openSidebar, openFilterSidebar, filterProductsBySearch,optionTextValue } =
    useProductsContext();
  const routerHistory = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const goToLoginPage = () => {
    window.location.href = "/signin";
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOut = () => {
    AuthService.logout();
  };

  const { categories } = useCategoryContext();
  const { total_items, clearCart } = useCartContext();

  const [searchQuery, setSearchQuery] = React.useState("");
  const dataFiltered = filterData(searchQuery, data);
  const [age, setAge] = React.useState("todos");

  const SearchBar = ({ setSearchQuery }) => (
    <form onSubmit={filterProductsBySearch}>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        style={{
          borderRadius: "0px",
        }}
      >
        <Select
          sx={{ minWidth: 120 }}
          size="small"
          style={{
            borderRadius: "0px",
            width: "6em",
            backgroundColor: "#E4E4E4",
            color: "#132A3C",
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          defaultValue={age}
          onChange={handleChange}
        >
          <MenuItem  value={"todos"}>Todos</MenuItem>
          {categories?.map((category) => {
            const { id, type } = category;
            return (
              <MenuItem key={id} value={type}>
                {type}
              </MenuItem>
            );
          })}
        </Select>
        <TextField
          name="search"
          size="small"
          id="search-bar"
          className="text"
          onInput={(e) => {
            //  setSearchQuery(e.target.value);
          }}
          variant="outlined"
          placeholder="Buscar..."
          style={{
            borderRadius: "0px",
          }}
        />
        <Button
          type="submit"
          onClick={()=>{
            routerHistory.push('/products');
          }}
          aria-label="search"
          style={{ borderRadius: "0px", backgroundColor: "#E32740" }}
        >
          <SearchIcon style={{ fill: "white", fontSize: "2em" }} />
        </Button>
      </ButtonGroup>
    </form>
  );

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  //  const { myUser } = useUserContext();
  const currentUser = AuthService.getCurrentUser();
  return (
    <NavContainer>
      <div className="nav-center">
        <div className="nav-header">
          <button type="button" className="nav-toggle" onClick={openSidebar}>
            <FaBars />
          </button>

          <Link className="logo-img" to="/">
            <img src={logo} alt="comfy sloth" />
          </Link>

          {currentUser ? (
            <div className="nav-user">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 4 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 32, height: 32 }}>
                      {currentUser.username.charAt(0).toUpperCase()}
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => {
                    window.location.href = "/profile";
                  }}
                >
                  <Avatar /> {currentUser.username}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    window.location.href = "/orders";
                  }}
                >
                  <Avatar /> Mis pedidos
                </MenuItem>
                <Divider />
                <MenuItem
                  onClick={() => {
                    clearCart();
                    localStorage.removeItem("user");
                    logOut({ returnTo: (window.location.href = "/") });
                  }}
                >
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Salir
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <button
              type="button"
              className="auth-btn nav-user"
              onClick={goToLoginPage}
              style={{
                fontSize: "16px",
              }}
            >
              Acceder <FaUser />
            </button>
          )}
          <Link
            to="/cart"
            className="cart-btn nav-user"
            style={{
              fontSize: "18px",
            }}
          >
            <span className="cart-container">
              <FaShoppingCart />
              <span className="cart-value">{total_items}</span>
            </span>
          </Link>

          <div
            className="search-bar nav-user"
            style={{
              alignSelf: "center",
              justifyContent: "center",
              flexDirection: "column",
              padding: 20,
            }}
          >
            <SearchBar
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
            />
          </div>
          {/*}
          <button
            type="button"
            className="nav-toggle"
            onClick={openFilterSidebar}
          >
            <FaFilter />
          </button>
          */}
        </div>
        <ul className="nav-links">
          {links.map((link) => {
            const { id, text, url } = link;
            return (
              <li key={id}>
                <Link to={url}>{text}</Link>
              </li>
            );
          })}
        </ul>

        <CartButtons />
      </div>
      
    </NavContainer>
  );
};

const NavContainer = styled.nav`
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .cart-btn {
    color: var(--clr-grey-1);
    font-size: 1.5rem;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-1);
    display: flex;

    align-items: center;
  }
  .cart-container {
    display: flex;
    align-items: center;
    position: relative;
    svg {
      height: 1.6rem;
      margin-left: 5px;
    }
  }
  .cart-value {
    position: absolute;
    top: -10px;
    right: -16px;
    background: var(--clr-ecomerce-5);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.75rem;
    color: var(--clr-white);
    padding: 12px;
  }
  .auth-btn {
    display: flex;
    align-items: center;
    background: transparent;
    border-color: transparent;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--clr-grey-1);
    letter-spacing: var(--spacing);
    svg {
      margin-left: 5px;
    }
  }
  .nav-center {
    width: 90vw;
    margin: 0 auto;
    /*max-width: var(--max-width);*/
  }
  .nav-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 175px;
      margin-left: -15px;
    }
  }
  .nav-toggle {
    background: transparent;
    border: transparent;
    color: var(--clr-menu-button);
    cursor: pointer;
    svg {
      font-size: 2rem;
    }
  }
  .nav-links {
    display: none;
  }
  .cart-btn-wrapper {
    display: none;
  }
  .search-bar {
    display: none;
  }
  .logo-img {
    margin-left: 10px;
  }
  @media (min-width: 992px) {
    .nav-toggle {
      display: none;
    }
    .nav-user {
      display: none;
    }
    .search-bar {
      display: flex;
    }
    .logo-img {
      margin-left: 0px;
    }
    .nav-center {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-items: center;
    }
    .nav-links {
      display: flex;
      justify-content: center;
      li {
        margin: 0 0.5rem;
      }
      a {
        color: var(--clr-grey-3);
        font-size: 1rem;
        text-transform: capitalize;
        letter-spacing: var(--spacing);
        padding: 0.5rem;
        &:hover {
          border-bottom: 2px solid var(--clr-ecomerce-5);
        }
      }
    }
    .cart-btn-wrapper {
      display: grid;
    }
  }
`;

export default Nav;
